import SSTEM from "../../Images/blogpics/afroprenueship/SSTEM.webp";

const FeaturedPostConts = {
  title: "7 Proven Strategies To Earn More Money As A Ghostwriter",
  date: "November 08, 2024",
  description:
    "Ghostwriting is a rewarding profession financially and intellectually. And so, the best ghostwriters work on themselves to maximize their earning potential...",
  image: SSTEM,
  author: "Afropreneurship",
};

export default FeaturedPostConts;
