import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className="bg-[#14143a] text-gray-300 py-10 text-sm">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-2xl font-bold text-white mb-4">Kick & Co</h2>
            <p className="text-gray-400 text-sm">
              We Ghostwrite books and ensure they’re sold! Our Ghostwriting and
              Marketing services also cater to other brands’ products and
              offers. We create and market content that sell your brand offerings to
              your ideal audience.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-semibold text-white mb-4">
                Quick Links
              </h3>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <NavLink
                    to="/ghostwriting"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Ghostwriting Service
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/copywriting"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Persuasive Copywriting
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/marketing"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Marketing and Public Relations
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/readktn"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Read KTN
                  </NavLink>
                </li>
                <li>
                <button
  className="hover:text-white transition-colors duration-200 cursor-pointer"
  onClick={() => {}}
>
  KTN Plus
</button>

                </li>
                <li>
                  <NavLink
                    to="/contact-us"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faq"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Faq
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/privacy-policy"
                    className="hover:text-white transition-colors duration-200"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white mb-4">
                Contact Us
              </h3>
              <p className="text-gray-400">
                Email:{" "}
                <a
                  href="mailto:info@kickandcosynergy.com"
                  className="hover:text-white transition-colors duration-200"
                >
                  info@kickandcosynergy.com
                </a>
                <br />
                Phone:{" "}
                <a
                  href="tel:+2349139070701"
                  className="hover:text-white transition-colors duration-200"
                >
                  +234 91-390-70701
                </a>
              </p>
              <div className="mt-4">
                <h3 className="text-xl font-semibold text-white mb-4">
                  Follow Us
                </h3>
                <div className="flex space-x-4">
                  <a
                    href="https://www.linkedin.com/company/kick-co-synergy-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-white transition-colors duration-200"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.23 0H1.77C.79 0 0 .77 0 1.72v20.56C0 23.23.79 24 1.77 24h20.46C23.21 24 24 23.23 24 22.28V1.72C24 .77 23.21 0 22.23 0zM7.06 20.452H3.56V9h3.5v11.452zM5.308 7.578c-1.11 0-2.002-.896-2.002-2.001a2.002 2.002 0 012.002-2.002c1.11 0 2.001.896 2.001 2.002 0 1.105-.891 2.001-2.001 2.001zM20.453 20.452h-3.5v-5.688c0-1.356-.028-3.1-1.894-3.1-1.894 0-2.184 1.482-2.184 3.004v5.784h-3.5V9h3.36v1.563h.048c.468-.885 1.61-1.818 3.319-1.818 3.55 0 4.204 2.34 4.204 5.384v6.323z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/kick.thenarrative/?g=5"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-white transition-colors duration-200"
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                    >
                      <title>Instagram icon</title>
                      <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                    </svg>
                  </a>

                  <a
                    href="https://www.youtube.com/@KICKTHENARRATIVE"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-white transition-colors duration-200"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.499 6.203c-.282-1.049-1.093-1.867-2.147-2.129C19.38 3.5 12 3.5 12 3.5s-7.38 0-9.352.574C1.593 4.337.782 5.154.5 6.203.01 8.053 0 12 0 12s.01 3.947.5 5.797c.282 1.049 1.093 1.867 2.147 2.129 1.972.574 9.352.574 9.352.574s7.38 0 9.352-.574c1.054-.262 1.865-1.08 2.147-2.129.49-1.85.5-5.797.5-5.797s-.01-3.947-.5-5.797zM9.75 15.5v-7l6.25 3.5-6.25 3.5z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-10">
          <p>
            &copy; {new Date().getFullYear()} Kick & Co. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
