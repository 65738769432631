import React from "react";
import { saveAs } from "file-saver";
import PricingFile from "./pricing.pdf"; // Import the PDF file

// Function to handle PDF download
const handleDownload = () => {
  saveAs(PricingFile, "KICK&CO SERVICES AND FEES.pdf");
};

const PricingPage = () => {
  return (
    <div className="flex flex-col items-center bg-gray-100 p-6 min-h-screen justify-start">
      <div className="max-w-5xl w-full bg-white rounded-lg shadow-md p-10 mt-20 md:mt-40">
        <h1 className="text-3xl font-bold mb-10 text-center">
          Our Pricing Plans
        </h1>
        <div className="flex justify-center">
          <button
            onClick={handleDownload}
            className="bg-[#14143a] text-white px-6 py-3 mb-10 rounded-lg shadow hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Download Pricing Document
          </button>
        </div>
        <p className="text-lg mb-10 text-justify">
          We're a team of Ghostwriters, Copywriters, Video producers, Editors,
          and Digital marketers who collaboratively create written and visual
          content that preserves, represents, and sells our clients’ brands,
          stories, services, and products to their ideal audience.
          
          <br /><br /> We service a
          global clientele including Coaches, Wealth strategists, C-suite
          professionals, Public speakers, Authors, Executive leaders,
          organization directors, world leaders, etc. <br /><br />We deliver projects and
          services including book ghostwriting, Social media and Web content
          creation, Sales video production, Brand/Platform Management, Marketing
          campaigns, and more..
        </p>
        <div className="mt-6 sm:mt-8 flex justify-center">
          <a
            href="https://calendly.com/kalukalu/discovery-call"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 rounded-md py-2 sm:py-3 px-4 sm:px-6 font-semibold sm:text-xl text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white text-xs md:text-sm"
          >
            Book a call
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
